import { useCallback, useEffect } from 'react'
import Parse from 'parse'
import { Link, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import Login from './components/pages/Login'
import useUserStore from './model/userState'
import Phone from './components/pages/Phone'
import { AppBar } from './components/molecules/AppBar'
import { Phones } from './components/pages/Phones'
import { Button } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { Box } from '@mui/system'
import { ExtOrMpk } from './components/pages/ExtOrMpk'
import HelpArticlePage from './components/pages/HelpArticlePage'
import { Markdown } from './components/atoms/Markdown'

Parse.initialize('Innovip-provisioning')
// eslint-disable-next-line no-undef
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER || '/parse'

function App() {
  const { user, init, initialised, assertValidSession } = useUserStore(
    useCallback(({ user, init, initialised, assertValidSession }) => {
      return { user, init, initialised, assertValidSession }
    }, [])
  )
  const navigate = useNavigate()
  useEffect(() => {
    init()
    const interval = setInterval(assertValidSession, 30000)
    return () => clearInterval(interval)
  }, [init, assertValidSession])
  useEffect(() => {
    if (!user && initialised) {
      navigate('/login')
    }
  }, [user, navigate, initialised])
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              {!!user && initialised && <AppBar />}
              <Box
                sx={{
                  maxWidth: '1200px',
                  margin: '0 auto',
                  padding: '0 1em',
                }}
              >
                <Outlet />
              </Box>
            </>
          }
        >
          <Route
            index
            element={<Link to="/phones">Voir liste des téléphones</Link>}
          />
          <Route path="phones" element={<Outlet />}>
            <Route index element={<Phones />} />
            <Route
              path=":phoneId"
              element={
                <>
                  <Button
                    sx={{ margin: '2em 0', padding: '0' }}
                    component={Link}
                    to="/phones"
                  >
                    <ArrowBack />
                    Retour vers la liste
                  </Button>
                  <Outlet />
                </>
              }
            >
              <Route index element={<Phone />} />
              <Route path="exts" element={<ExtOrMpk />} />
            </Route>
          </Route>
          <Route
            path="terms-and-conditions"
            element={
              <div>
                <Button
                  sx={{ margin: '2em 0', padding: '0' }}
                  component={Link}
                  to="/phones"
                >
                  <ArrowBack />
                  Retour
                </Button>
                <Markdown url="page-content/terms.md" />
              </div>
            }
          />
          <Route
            path="cookies"
            element={
              <div>
                <Button
                  sx={{ margin: '2em 0', padding: '0' }}
                  component={Link}
                  to="/phones"
                >
                  <ArrowBack />
                  Retour
                </Button>
                <Markdown url="page-content/cookies.md" />
              </div>
            }
          />
          <Route
            path="help/:articleId"
            element={
              <div>
                <Button
                  sx={{ margin: '2em 0', padding: '0' }}
                  component={Link}
                  to="/phones"
                >
                  <ArrowBack />
                  Retour
                </Button>
                <HelpArticlePage />
              </div>
            }
          />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  )
}

export default App
