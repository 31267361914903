import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { TextInput, Select, Toggle } from '../atoms/Input'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { FormApi } from 'final-form'
import { Phone as PhoneModel, usePhone } from '../../model/phones'
import { OperationFeedback } from '../molecules/OperationFeedback'
import { LoadingButton } from '../atoms/LoadingButton'
import useUserStore from '../../model/userState'

// hide fields when ldap directory is false
// hide section if no ldap server
const Phone = () => {
  const [successToastVisible, setSuccessToastVisible] = useState(false)
  const [errorToastVisible, setErrorToastVisible] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const { phoneId } = useParams()
  const { phone, setPhone } = usePhone(error, phoneId)
  const [loading, setLoading] = useState(false)
  const refetchUser = useUserStore(({ refetchUser }) => refetchUser)
  return (
    <>
      <Form
        onSubmit={(values) => {
          for (const [key, value] of Object.entries(values)) {
            phone?.set(key, value)
          }
          setLoading(true)
          phone
            ?.save()
            .then((savedPhone) => {
              setPhone(savedPhone)
              setSuccessToastVisible(true)
            })
            .catch((e) => {
              setError(e)
              setErrorToastVisible(true)
            })
            .finally(() => {
              setLoading(false)
              refetchUser()
            })
        }}
        initialValues={{
          label1: phone?.get('label1'),
          label2: phone?.get('label2'),
          language: phone?.get('language'),
          timeformat: phone?.get('timeformat'),
          ringtonesrc: phone?.get('ringtonesrc'),
          ringtoneid: phone?.get('ringtoneid'),
          wifi_enabled: phone?.get('wifi_enabled'),
          wifi_ssid: phone?.get('wifi_ssid'),
          wifi_password: phone?.get('wifi_password'),
          has_dir: phone?.get('has_dir'),
          dir_name: phone?.get('dir_name'),
          handset_plus_headset: phone?.get('handset_plus_headset'),
          default_ringtone: phone?.get('default_ringtone'),
        }}
        validate={({ label1, label2, dir_name, has_dir }) => {
          const doesNotNeedDirName = !(phone?.get('ldapserver') && has_dir)
          const dirNameValid =
            dir_name && dir_name.length <= 15 && dir_name.length >= 1
          return {
            label1:
              label1 && label1.length <= 20
                ? undefined
                : new Error('Le nom doit avoir entre 1 et 20 caractère.'),
            label2:
              !label2 || label2.length <= 20
                ? undefined
                : new Error('Le nom doit avoir entre 1 et 20 caractère.'),
            dir_name:
              doesNotNeedDirName || dirNameValid
                ? undefined
                : new Error(
                    'Le nom de répertoire doit avoir entre 1 et 15 caractère.'
                  ),
          }
        }}
      >
        {({ handleSubmit, form }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: 'grid',
              gap: '2rem',
              margin: '0 auto',
              gridTemplateRows: 'repeat(12, auto)',
              gridTemplateColumns: { md: '1fr 1fr', xs: '1fr' },
            }}
          >
            <Typography
              variant="h4"
              sx={{ gridColumn: { md: 'span 2', xs: '1 / 1' } }}
            >
              Téléphone
            </Typography>
            <Card component="fieldset" sx={{ border: 'none' }}>
              <CardContent sx={{ display: 'grid', gridGap: '1.5rem' }}>
                <Typography variant="h6" component="legend">
                  Compte principal
                </Typography>
                <TextInput
                  name="label1"
                  label="Nom"
                  helperText="Nom affiché sur l'écran"
                />
                <div>
                  <Typography variant="subtitle2" color="text.secondary">
                    Nom connu dans la centrale:
                  </Typography>
                  <Typography variant="body1">{phone?.get('user1')}</Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    Serveur de téléphonie:
                  </Typography>
                  <Typography variant="body1">
                    {phone?.get('sipserver1')}
                  </Typography>
                </div>
              </CardContent>
            </Card>
            {phone?.get('user2') ? (
              <Card
                component="fieldset"
                sx={{
                  gridColumnStart: { md: 2, xs: 1 },
                  gridRow: { md: '2 / span 12', xs: 'auto' },
                  alignSelf: 'start',
                  border: 'none',
                }}
              >
                <CardContent sx={{ display: 'grid', gridGap: '1.5rem' }}>
                  <Typography variant="h6" component="legend">
                    Compte secondaire (facultatif):
                  </Typography>
                  <TextInput
                    name="label2"
                    label="Nom du compte secondaire"
                    helperText="Nom du second compte"
                  />
                  <div>
                    <Typography variant="subtitle2" color="text.secondary">
                      Nom connu de la centrale pour le compte secondaire:
                    </Typography>
                    <Typography variant="body1">
                      {phone?.get('user2')}&nbsp;
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      Serveur de téléphonie secondaire:
                    </Typography>
                    <Typography variant="body1">
                      {phone?.get('sipserver2')}&nbsp;
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <Box
                sx={{
                  gridColumnStart: { md: 2, xs: 1 },
                  gridRow: { md: '2 / span 12', xs: 'auto' },
                  alignSelf: 'start',
                  border: 'none',
                }}
              />
            )}
            <Card component="fieldset" sx={{ border: 'none' }}>
              <CardContent sx={{ display: 'grid', gridGap: '1.5rem' }}>
                <Typography variant="h6" component="legend">
                  Caractéristiques du poste
                </Typography>
                <div>
                  <Typography variant="subtitle2" color="text.secondary">
                    Identifiant du poste (voir à l'arrière):
                  </Typography>
                  <Typography variant="body1">{phone?.get('mac')}</Typography>
                </div>
                <Select
                  name="language"
                  label="Langue"
                  options={[
                    { value: 'fr', label: 'Francais' },
                    { value: 'nl', label: 'Nederlands' },
                    { value: 'en', label: 'English' },
                    { value: 'it', label: 'Italian' },
                    { value: 'es', label: 'Spanish' },
                    { value: 'pt', label: 'Portuguese' },
                  ]}
                  helperText="Langue d'interaction du téléphone"
                />
                <Select
                  name="timeformat"
                  label="Heure"
                  options={[
                    { value: '0', label: '12H' },
                    { value: '1', label: '24H' },
                  ]}
                  helperText="Représentation de l'heure (format 12H ou 24H)"
                />
                <Wifi form={form} phone={phone} />

                <TextInput
                  name="ringtonesrc"
                  type="number"
                  parse={(val) => Number.parseInt(val)}
                  label="Numéro spécial"
                  helperText="Le numéro qui fera sonner votre poste différement (ex: 224, votre collègue)"
                />
                <Select
                  name="ringtoneid"
                  label="Sonnerie spéciale"
                  options={[
                    { label: 'Défaut', value: '0' },
                    { label: 'Sonnerie 1', value: '1' },
                    { label: 'Sonnerie 2', value: '2' },
                    { label: 'Sonnerie 3', value: '3' },
                    { label: 'Sonnerie 4', value: '4' },
                    { label: 'Sonnerie 5', value: '5' },
                    { label: 'Sonnerie 6', value: '6' },
                    { label: 'Sonnerie 7', value: '7' },
                  ]}
                  helperText="Sonnerie sur numéro particulier (voir champ précédent)"
                />
                <Toggle
                  label="Faire sonner le post en même temps que le casque"
                  helperText=""
                  name="handset_plus_headset"
                />
                <Select
                  name="default_ringtone"
                  label="Sonnerie par défaut"
                  options={[
                    { label: 'Sonnerie 1', value: '1' },
                    { label: 'Sonnerie 2', value: '2' },
                    { label: 'Sonnerie 3', value: '3' },
                    { label: 'Sonnerie 4', value: '4' },
                  ]}
                  helperText=""
                />
              </CardContent>
            </Card>
            {phone?.get('ldapserver') && (
              <Card component="fieldset" sx={{ border: 'none' }}>
                <CardContent sx={{ display: 'grid', gridGap: '1.5rem' }}>
                  <Typography variant="h6" component="legend">
                    Répertoire
                  </Typography>
                  <Toggle
                    label="Répertoire existant"
                    name="has_dir"
                    helperText="Il y a-t-il une directory associée à ce téléphone ?"
                  />
                  {form.getFieldState('has_dir')?.value && (
                    <>
                      <TextInput
                        name="dir_name"
                        type="string"
                        label="Nom du répertoire"
                        helperText="Nom du répertoire associé à ce téléphone"
                      />
                      <div>
                        <Typography variant="subtitle2" color="text.secondary">
                          Sur ce serveur, les postes vont chercher leur
                          répertoire
                        </Typography>
                        <Typography variant="body1">
                          {phone?.get('ldapserver')}
                        </Typography>
                      </div>
                    </>
                  )}
                </CardContent>
              </Card>
            )}
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!form.getState().dirty || form.getState().invalid}
              loading={loading}
            >
              Sauver les changements
            </LoadingButton>
          </Box>
        )}
      </Form>{' '}
      <OperationFeedback
        setSuccessToastInvisible={() => setSuccessToastVisible(false)}
        successToastVisible={successToastVisible}
        setErrorToastInvisible={() => setErrorToastVisible(false)}
        errorToastVisible={errorToastVisible}
        error={error}
      />
    </>
  )
}

const Wifi: React.FC<{ phone?: PhoneModel | null; form: FormApi }> = ({
  phone,
  form,
}) => {
  return phone?.get('has_wifi') ? (
    <fieldset
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        border: 'none',
        gap: '2rem',
        padding: 0,
      }}
    >
      <div style={{ gridColumn: 'span 2' }}>
        <Toggle
          name="wifi_enabled"
          label="Wifi"
          helperText="Valable uniquement pour les postes qui le supportent"
        />
      </div>
      {form.getFieldState('wifi_enabled')?.value && (
        <>
          <TextInput
            name="wifi_ssid"
            label="SSID"
            helperText="SSID (nom) du WiFi"
          />
          <TextInput
            name="wifi_password"
            label="Mot de passe WiFi"
            helperText="Mot de passe du WiFi"
            type="password"
          />
        </>
      )}
    </fieldset>
  ) : null
}

export default Phone
