import React, { useCallback, useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import CssBaseline from '@mui/material/CssBaseline'
import { TextField, Typography } from '@mui/material'
import useUserStore from '../../model/userState'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const { login, loginError, user } = useUserStore(
    useCallback(({ login, loginError, user }) => {
      return { login, loginError, user }
    }, [])
  )
  const navigate = useNavigate()
  useEffect(() => {
    if (user) {
      navigate('/phones')
    }
  }, [user, navigate])
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <CssBaseline />
      <Form
        onSubmit={({ username, password }) => {
          login({ username, password }).then(() => {
            navigate('/phones')
          })
        }}
        initialValues={{ username: '', password: '' }}
      >
        {({ handleSubmit }) => (
          <Paper
            component="form"
            onSubmit={handleSubmit}
            method="POST"
            elevation={12}
            sx={{
              display: 'grid',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              margin: '0 auto',
              padding: { xs: '1rem 2rem', md: '2rem 4rem' },
              width: 'fit-content',
            }}
          >
            <Typography variant="h4">Login</Typography>
            <Field name="username" type="text">
              {({ input }) => {
                return (
                  <TextField
                    label="Nom d'utilisateur"
                    name={input.name}
                    value={input.value}
                    type={input.type}
                    onChange={input.onChange}
                  />
                )
              }}
            </Field>
            <Field name="password" type="password">
              {({ input }) => (
                <TextField
                  label="Mot de passe"
                  name={input.name}
                  value={input.value}
                  type={input.type}
                  onChange={input.onChange}
                />
              )}
            </Field>
            <Typography variant="body1">
              {loginError && 'Erreur de login'}
            </Typography>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Paper>
        )}
      </Form>
    </div>
  )
}

Login.propTypes = {}

export default Login
