import { Add, DeleteForever, Edit } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Typography,
  Card,
  CardContent,
} from '@mui/material'
import React, { useState } from 'react'
import { Form, useField } from 'react-final-form'
import { useParams } from 'react-router-dom'
import {
  useExtsOrMpks,
  ExtOrMpk as ExtOrMpkModel,
  usePhone,
} from '../../model/phones'
import { DangerButton } from '../atoms/DangerButton'
import { TextInput, Select, Toggle } from '../atoms/Input'
import { LoadingButton } from '../atoms/LoadingButton'
import { OperationFeedback } from '../molecules/OperationFeedback'

const MpkExtFields: React.FC = () => {
  const field = useField('empty')
  return (
    <>
      <div>
        <Toggle 
          label="Touche inactive"
          name="empty"
          helperText="Touche non utilisée."
        />
      </div>
      <Select
        name="type"
        label="Type"
        options={[
          { value: '0', label: 'Numéro extérieur' },
          { value: '1', label: 'Numéro interne' },
        ]}
        helperText="Voulez-vous connecter ce bouton à un numéro interne ou externe ?"
        disabled={field.input.value}
      />
      <Select
        name="account"
        label="Compte"
        options={[
          { value: '0', label: 'Compte 1' },
          { value: '1', label: 'Compte 2' },
        ]}
        helperText="Quel est le compte qui sera utilisé pour ce bouton. Par défaut, ou si vous ne savez pas, utilisez le Compte 1"
        disabled={field.input.value}
      />
      <TextInput
        name="name"
        label="Nom"
        helperText="Nom associé à votre correspondant (Ex: Yves)"
        type="text"
        disabled={field.input.value}
      />
      <TextInput
        name="number"
        label="Numéro"
        helperText="Numéro associé à votre correspondant (Ex: 322 ou 0478123456 ou 0032478274786)"
        type="text"
        disabled={field.input.value}
      />
    </>
  )
}

export const ExtOrMpk: React.FC = () => {
  const [successToastVisible, setSuccessToastVisible] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const { phoneId } = useParams()
  const { phone } = usePhone(error, phoneId)
  const {
    extsOrMpks,
    canAddMore,
    newKey,
    updateExtOrMpk,
    deleteExtOrMpk,
    createNewKey,
    deleteNewKey,
    saveNewKey,
    loading,
  } = useExtsOrMpks(phone, error)
  const [editedIndex, setEditedIndex] = useState<number | null>(null)
  return (
    <Box sx={{ paddingBottom: '1.5em' }}>
      {extsOrMpks?.map((extOrMpk, i) => (
        <KeyForm
          loading={loading}
          index={i}
          key={extOrMpk.id}
          extOrMpk={extOrMpk}
          isBeingEdited={i === editedIndex}
          setIsBeingEdited={() => {
            setEditedIndex(editedIndex === i ? null : i)
            deleteNewKey()
          }}
          onSubmit={async (values) => {
            await updateExtOrMpk(i, values).catch((err) => setError(err))
            setEditedIndex(null)
            setSuccessToastVisible(true)
          }}
          onDelete={async () => {
            await deleteExtOrMpk(i).catch((err) => setError(err))
            setEditedIndex(null)
            setSuccessToastVisible(true)
          }}
        />
      ))}
      {canAddMore && (
        <>
          {!!newKey && (
            <KeyForm
              loading={loading}
              extOrMpk={newKey}
              onSubmit={(vals) => {
                saveNewKey(vals).catch((err) => setError(err))
              }}
              onDelete={deleteNewKey}
              index={extsOrMpks?.length || 0}
              isBeingEdited
              setIsBeingEdited={() => {
                setEditedIndex(null)
                if (newKey) {
                  deleteNewKey()
                }
              }}
            />
          )}
          <Button
            onClick={async () => {
              createNewKey()
              setEditedIndex(null)
            }}
            type="button"
            variant="outlined"
            startIcon={<Add />}
            sx={{ marginTop: '1.5rem' }}
          >
            Ajouter une touche
          </Button>
        </>
      )}
      <OperationFeedback
        setSuccessToastInvisible={() => setSuccessToastVisible(false)}
        successToastVisible={successToastVisible && !error}
        setErrorToastInvisible={() => {
          setSuccessToastVisible(false)
          setError(null)
        }}
        errorToastVisible={!!error}
        error={error}
      />
    </Box>
  )
}

const KeyForm: React.FC<{
  extOrMpk: ExtOrMpkModel
  onSubmit: (values: Record<string, any>) => void
  onDelete: () => void
  index: number
  isBeingEdited: boolean
  setIsBeingEdited?: () => void
  loading: boolean
}> = ({
  extOrMpk,
  onSubmit,
  onDelete,
  index,
  isBeingEdited,
  setIsBeingEdited,
  loading,
}) => {
  return (
    <Card>
      <CardContent>
        <Form
          initialValues={{
            account: extOrMpk.get('account'),
            name: extOrMpk.get('name'),
            number: extOrMpk.get('number'),
            type: extOrMpk.get('type'),
            empty: extOrMpk.get('empty')
          }}
          validate={({ number, name, empty }) => ({
            number:
              (number && /^[0-9*#]{2,16}$/.test(number)) || empty
                ? undefined
                : new Error('Le numéro doit contenir entre 2 et 15 chiffres.'),
            name:
              (name && name.length <= 20) || empty
                ? undefined
                : new Error('Le nom doit contenir entre 1 et 20 caractères.'),
          })}
          onSubmit={(values) => {
            onSubmit(values)
          }}
        >
          {({ handleSubmit, form }) => {
            const formState = form.getState()
            return (
              <Box
                component="form"
                sx={{
                  display: 'grid',
                  gap: '1rem',
                  gridTemplateColumns: {
                    md: 'auto repeat(5, 1fr)',
                    xs: 'auto',
                  },
                  gridTemplateRows: {
                    md: isBeingEdited ? 'repeat(2, auto)' : 'auto',
                    xs: 'repeat(8, auto)',
                  },
                  alignItems: isBeingEdited ? 'flex-start' : 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h6">Touche {index + 1}</Typography>
                  <IconButton
                    onClick={setIsBeingEdited}
                    sx={{
                      display: { md: 'none' },
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Box>
                {isBeingEdited ? (
                  <>
                    <MpkExtFields />

                    <Box
                      sx={{
                        display: 'grid',
                        gap: '1.5rem',
                        gridTemplateColumns: {
                          md: 'repeat(3, auto)',
                          xs: 'auto',
                        },
                        gridColumn: { md: 'span 3' },
                      }}
                    >
                      <LoadingButton
                        disabled={!formState.dirty || !formState.valid}
                        variant="outlined"
                        type="submit"
                        onClick={handleSubmit}
                        loading={loading}
                      >
                        Sauvegarder
                      </LoadingButton>

                      {setIsBeingEdited && (
                        <Button
                          variant="text"
                          type="button"
                          onClick={setIsBeingEdited}
                          disabled={loading}
                        >
                          annuler
                        </Button>
                      )}
                      <DangerButton
                        onClick={() => {
                          onDelete()
                        }}
                        startIcon={<DeleteForever />}
                        loading={loading}
                      >
                        Supprimer
                      </DangerButton>
                    </Box>
                  </>
                ) : (
                  <>
                  {extOrMpk.get('empty')? <Typography sx={{justifySelf: {md: 'center'}, fontStyle: 'italic'}} variant='body1'>Vide.</Typography> : 
                    <><Typography sx={{ justifySelf: { md: 'center' } }}>
                      {extOrMpk.get('type') === '0'
                        ? 'Numéro extérieur'
                        : 'Numéro interne'}
                    </Typography>
                    <Typography sx={{ justifySelf: { md: 'center' } }}>
                      Compte {Number.parseInt(extOrMpk.get('account')) + 1}
                    </Typography>
                    <Typography sx={{ justifySelf: { md: 'center' } }}>
                      {extOrMpk.get('name')}
                    </Typography>

                    <Typography sx={{ justifySelf: { md: 'center' } }}>
                      {extOrMpk.get('number')}
                    </Typography></>}
                    <IconButton
                      onClick={setIsBeingEdited}
                      sx={{
                        gridColumnStart: '6',
                        display: { xs: 'none', md: 'initial' },
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </>
                )}
              </Box>
            )
          }}
        </Form>
      </CardContent>
    </Card>
  )
}
