import React, { useCallback, useState } from 'react'
import {
  AppBar as MuiAppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material'
import { AccountCircle, Help, Loop } from '@mui/icons-material'
import useUserStore from '../../model/userState'
import { useNavigate, useParams } from 'react-router-dom'
import { useHelpArticles } from '../../model/helpArticles'
import { LoadingButton } from '../atoms/LoadingButton'
import { OperationFeedback } from './OperationFeedback'
import { usePhone } from '../../model/phones'

export const AppBar = () => {
  const { logout, user, generateConfig, loading } = useUserStore(
    useCallback(({ logout, user, generateConfig, loading }) => {
      return { logout, user, generateConfig, loading }
    }, [])
  )
  const params = useParams()
  const { phone } = usePhone(null, params.phoneId)
  const { articles } = useHelpArticles()
  const [appMenuAnchorEl, setAppMenuAnchorEl] = useState<HTMLElement | null>(
    null
  )
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState<HTMLElement | null>(
    null
  )
  const navigate = useNavigate()

  const [successToastVisible, setSuccessToastVisible] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  return (
    <>
      <MuiAppBar position="sticky">
        <Toolbar>
          <div>
            <img
              src="/INNOV-IP-logo-clair2.png"
              alt=""
              style={{ height: '3em' }}
            />
          </div>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, padding: '0 1em' }}
          >
            Téléphones {user?.get('company_name')}{' '}
            {phone && <>- {phone?.get('label1')}</>}
          </Typography>
          <LoadingButton
            variant="contained"
            color="warning"
            title="Applique vos dernières modifications."
            onClick={() =>
              generateConfig()
                .then(() => setSuccessToastVisible(true))
                .catch((err) => setError(err))
            }
            loading={loading}
            sx={{
              display: user?.get('configuration_generated')
                ? 'none'
                : undefined,
            }}
          >
            Générer la configuration
          </LoadingButton>
          {loading && (
            <Loop
              sx={{
                '@keyframes rotate': {
                  '100%': {
                    transform: 'rotate(-360deg)',
                  },
                },
                animation: 'rotate 1.2s infinite linear',
              }}
            />
          )}

          <OperationFeedback
            setSuccessToastInvisible={() => setSuccessToastVisible(false)}
            successToastVisible={successToastVisible && !error}
            setErrorToastInvisible={() => {
              setSuccessToastVisible(false)
              setError(null)
            }}
            errorToastVisible={!!error}
            error={error}
          />
          <div>
            <IconButton
              size="large"
              aria-label="help"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(e) => setHelpMenuAnchorEl(e.currentTarget)}
              color="inherit"
            >
              <Help />
            </IconButton>
            <Menu
              id="menu-help"
              anchorEl={helpMenuAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={!!helpMenuAnchorEl}
              onClose={() => {
                setHelpMenuAnchorEl(null)
              }}
            >
              {articles.map((article) => {
                return (
                  <MenuItem
                    key={article.id}
                    onClick={() => {
                      navigate(`/help/${article.id}`)
                      setHelpMenuAnchorEl(null)
                    }}
                  >
                    {article.get('title')}
                  </MenuItem>
                )
              })}
            </Menu>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(e) => setAppMenuAnchorEl(e.currentTarget)}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={appMenuAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(appMenuAnchorEl)}
              onClose={() => {
                setAppMenuAnchorEl(null)
              }}
            >
              <MenuItem
                onClick={() => {
                  logout()
                }}
              >
                Logout
              </MenuItem>
              <MenuItem onClick={() => navigate('/terms-and-conditions')}>
                Conditions d'utilisation et Charte vie privée
              </MenuItem>
              <MenuItem onClick={() => navigate('/cookies')}>
                Configuration cookies
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </MuiAppBar>
    </>
  )
}
