import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { Loop } from '@mui/icons-material'

export const LoadingButton: React.FC<
  { loading: boolean; disabled?: boolean } & ButtonProps
> = ({ loading, children, disabled, ...buttonProps }) => {
  return (
    <Button {...buttonProps} disabled={loading || disabled}>
      {loading ? (
        <Loop
          sx={{
            '@keyframes rotate': {
              '100%': {
                transform: 'rotate(-360deg)',
              },
            },
            animation: 'rotate 1.2s infinite linear',
          }}
        />
      ) : (
        children
      )}
    </Button>
  )
}
