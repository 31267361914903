import React from 'react'
import { Field } from 'react-final-form'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
  TextField,
} from '@mui/material'

export const TextInput: React.FC<{
  name: string
  type?: string
  label?: string
  helperText: string
  disabled?: boolean
  parse?: (val: string) => any
}> = ({ name, type = 'text', label, helperText, disabled = false, parse }) => {
  return (
    <Field name={name} type={type} disabled={disabled} parse={parse}>
      {({ input, meta }) => {
        return (
          <TextField
            label={label}
            helperText={
              meta.dirty && meta.error?.message
                ? meta.error?.message
                : helperText
            }
            {...input}
            disabled={disabled}
            error={meta.dirty && !!meta.error?.message}
          />
        )
      }}
    </Field>
  )
}

export const Select: React.FC<{
  options: { label: string; value: string }[]
  name: string
  helperText: string
  label: string
  disabled?: boolean
}> = ({ options, name, helperText, label, disabled }) => {
  return (
    <Field name={name}>
      {({ input }) => (
        <TextField
          select
          label={label}
          helperText={helperText}
          SelectProps={{ native: true }}
          disabled={disabled}
          {...input}
        >
          {options.map(({ label, value: optionValue }) => (
            <option value={optionValue} key={optionValue}>
              {label}
            </option>
          ))}
        </TextField>
      )}
    </Field>
  )
}

export const Toggle: React.FC<{
  label: string
  name: string
  helperText: string
}> = ({ label, name, helperText }) => {
  return (
    <Field name={name} type="checkbox">
      {({ input }) => (
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={input.checked}
                onChange={(e) => {
                  input.onChange(e)
                }}
              />
            }
            label={label}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  )
}
