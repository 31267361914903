import { Close } from '@mui/icons-material'
import { Alert, IconButton, Snackbar } from '@mui/material'
import React from 'react'
const ERROR_MESSAGE_MAP: Record<string, string> = {
  LOCAL_CSV_FILE_WRITING_ERROR:
    "Attention: erreur sur le serveur d'application, votre configuration n'a pas été enregistrée. Contactez InnovIP (suppport@innovip.be)",
  REMOTE_CSV_FILE_WRITING_ERROR:
    "Attention: erreur sur le serveur de configuration, votre configuration n'a pas été enregistrée. Contactez InnovIP (suppport@innovip.be)",
  WRONG_MAC:
    "Attention: information non valide. L'adresse MAC n'est pas valable. Contactez InnovIP (suppport@innovip.be)",
  NO_TEMPLATE:
    "Attention: information non valide. Le template n'existe pas. Contactez InnovIP (suppport@innovip.be)",
  MISSING_CSV_FILE:
    "Attention: information non valide Le fichier des postes n'existe pas !. Contactez InnovIP (suppport@innovip.be).",
  CUSTOMER_NO_PASSWORD_NOT_SUPPORTED:
    "Attention: information non valide. Ce client n'est pas encore configuré pour cette fonctionnalité. Contactez InnovIP (suppport@innovip.be).",
  PHONE_NO_PASSWORD_NOT_SUPPORTED:
    "Attention: information non valide. Ce téléphone n'est pas encore configuré pour cette fonctionnalité. Contactez InnovIP (support@innovip.be). ",
}
export const OperationFeedback: React.FC<{
  successToastVisible: boolean
  setSuccessToastInvisible: () => void
  errorToastVisible: boolean
  setErrorToastInvisible: () => void
  error: Error | null
}> = ({
  successToastVisible,
  setSuccessToastInvisible,
  errorToastVisible,
  setErrorToastInvisible,
  error,
}) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={successToastVisible}
        autoHideDuration={6000}
        onClose={() => setSuccessToastInvisible()}
      >
        <Alert severity="success">
          Félicitations - N'oubliez pas de rédémarrer votre poste téléphonique.
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSuccessToastInvisible()}
          >
            <Close fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={errorToastVisible}
        autoHideDuration={6000}
        onClose={() => setErrorToastInvisible()}
      >
        <Alert severity="error">
          {(error && ERROR_MESSAGE_MAP[error.message]) ||
            'Une erreur est survenue lors de la sauvegarde.'}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setErrorToastInvisible()}
          >
            <Close fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
    </>
  )
}
