import React, { useState } from 'react'
import { Edit, Phone } from '@mui/icons-material'
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Phone as PhoneModel, usePhones } from '../../model/phones'

export const Phones = () => {
  const { phones } = usePhones()
  return (
    <>
      <List dense={false} sx={{ margin: '0 auto', maxWidth: '500px' }}>
        {phones?.map((phone) => (
          <ListItem
            key={phone.id}
            secondaryAction={<EditPhoneAction phone={phone} />}
          >
            <ListItemAvatar>
              <Avatar>
                <Phone />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={phone.get('label1')}
              secondary={phone.get('user1')}
            />
          </ListItem>
        ))}
      </List>
    </>
  )
}

const EditPhoneAction: React.FC<{ phone: PhoneModel }> = ({ phone }) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  return (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        edge="end"
        aria-label="editer"
      >
        <Edit />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => navigate(`/phones/${phone.id}`)}>
          Éditer la configuration du téléphone
        </MenuItem>
        {phone.get('mpk_number') + phone.get('ext_number') > 0 && (
          <MenuItem onClick={() => navigate(`/phones/${phone.id}/exts`)}>
            Éditer les touches d'extensions
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
