import React from 'react'
import { useParams } from 'react-router-dom'
import StaticMarkdown from 'markdown-to-jsx'
import { Typography } from '@mui/material'
import { useHelpArticles } from '../../model/helpArticles'

const dummyArticle = {
  get: (key: 'content' | 'title') =>
    ({
      content: 'Cliquez sur "retour" pour revenir vers la page principale',
      title: 'Nous ne trouvons pas cette page',
    }[key]),
}

const HelpArticlePage = () => {
  const { articleId } = useParams()
  const { articles } = useHelpArticles()
  const article = articles.find((a) => a.id === articleId) || dummyArticle

  return (
    <div style={{ padding: '1.5rem 0' }}>
      <Typography variant="h4" component="h1">
        {article && article.get('title')}
      </Typography>
      <StaticMarkdown
        options={{
          overrides: {
            h1: (props) => (
              <Typography
                {...props}
                variant="h4"
                component="h2"
                sx={{ paddingBottom: '1.5rem' }}
              />
            ),
            h2: (props) => (
              <Typography
                {...props}
                variant="h5"
                component="h3"
                sx={{ padding: '2rem 0 0.8rem' }}
              />
            ),
            h3: (props) => (
              <Typography
                {...props}
                variant="h6"
                component="h4"
                sx={{ padding: '2rem 0 0.8rem' }}
              />
            ),
            p: (props) => (
              <Typography {...props} variant="body1" component="p" />
            ),
            li: (props) => (
              <Typography {...props} variant="body1" component="li" />
            ),
          },
        }}
      >
        {article ? article.get('content') : 'Chargement en cours...'}
      </StaticMarkdown>
    </div>
  )
}

export default HelpArticlePage
