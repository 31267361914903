import create from 'zustand'
import Parse from 'parse'

type UserStore = {
  user?: Parse.User
  loginError: any
  initialised: boolean
  login: (loginInfo: {
    username: string
    password: string
  }) => Promise<Parse.User | void>
  logout: () => Promise<void>
  init: () => void
  assertValidSession: () => void
  generateConfig: () => Promise<void>
  refetchUser: () => Promise<void>
  loading: boolean
}

const useUserStore = create<UserStore>((set, get) => ({
  user: undefined,
  loginError: undefined,
  initialised: false,
  loading: false,
  login: async ({ username, password }) =>
    Parse.User.logIn(username, password)
      .then(async (loggedInUser) => {
        const user = await loggedInUser.fetch()
        set({ user })
        return loggedInUser
      })
      .catch((e) => {
        set({ loginError: e })
      }),
  logout: async () => {
    await Parse.User.logOut()
    set({ user: undefined })
  },
  init: () => {
    Parse.Session.current()
      .then((session) => {
        if (session.get('expiresAt') > Date.now()) {
          set({ user: Parse.User.current(), initialised: true })
        } else {
          set({ user: undefined, initialised: true })
        }
      })
      .catch(() => {
        set({ user: undefined, initialised: true })
      })
  },
  assertValidSession: () =>
    Parse.Session.current()
      .then((session) => {
        if (session.get('expiresAt') <= Date.now()) {
          set({ user: undefined })
        }
      })
      .catch(() => {
        set({ user: undefined, initialised: true })
      }),
  generateConfig: async () => {
    set({ loading: true })
    await get()
      .user?.save('configuration_generated', true)
      .finally(() => {
        get().refetchUser()
        set({ loading: false })
      })
  },
  refetchUser: async () => {
    set({ loading: true })
    const user = await get().user?.fetch()
    set({ user, loading: false })
  },
}))

export default useUserStore
