import { Warning } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { LoadingButton } from './LoadingButton'

export const DangerButton: React.FC<{
  loading?: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
  startIcon?: React.ReactNode
}> = ({ onClick, loading, children, startIcon, ...props }) => {
  const [hasBeenClicked, setHasBeenClicked] = useState(false)
  useEffect(() => {
    if (hasBeenClicked) {
      const timeout = setTimeout(() => {
        setHasBeenClicked(false)
      }, 5000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [hasBeenClicked, setHasBeenClicked])

  return (
    <LoadingButton
      loading={loading || false}
      type="button"
      color="error"
      {...props}
      onClick={(ev) => {
        if (hasBeenClicked) {
          onClick(ev)
        } else {
          setHasBeenClicked(true)
        }
      }}
      startIcon={hasBeenClicked ? <Warning /> : startIcon}
    >
      {hasBeenClicked ? 'Confirmer ?' : children}
    </LoadingButton>
  )
}
