import React, { useEffect, useState } from 'react'
import StaticMarkdown from 'markdown-to-jsx'
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export const Markdown: React.FC<{ url: string }> = ({ url }) => {
  const [content, setContent] = useState('Chargement en cours...')
  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then((res) => setContent(res))
      .catch(() =>
        setContent(
          'Erreur dans le chargement de la page, veuillez contacter un administrateur.'
        )
      )
  }, [url, setContent, content])

  return (
    <div style={{ padding: '1.5rem 0' }}>
      <StaticMarkdown
        options={{
          overrides: {
            h1: (props) => (
              <Typography
                {...props}
                variant="h3"
                component="h1"
                sx={{ paddingBottom: '1.5rem' }}
              />
            ),
            h2: (props) => (
              <Typography
                {...props}
                variant="h4"
                component="h2"
                sx={{ padding: '2rem 0 0.8rem' }}
              />
            ),
            p: (props) => (
              <Typography {...props} variant="body1" component="p" />
            ),
            li: (props) => (
              <Typography {...props} variant="body1" component="li" />
            ),
            a: (props) => (
              <Link to={props.href} title={props.title}>
                {props.children}
              </Link>
            ),
          },
        }}
      >
        {content}
      </StaticMarkdown>
    </div>
  )
}
