import Parse from 'parse'
import { useCallback, useEffect, useState } from 'react'
import useUserStore from './userState'

export class ExtOrMpk extends Parse.Object {
  type: string = ''
  account: string = ''
  name: string = ''
  number: string = ''
}
export class Ext extends ExtOrMpk {
  constructor() {
    super('Ext')
  }
}
export class Mpk extends ExtOrMpk {
  constructor() {
    super('Mpk')
  }
}
Parse.Object.registerSubclass('Ext', Ext)
Parse.Object.registerSubclass('Mpk', Mpk)

export class Phone extends Parse.Object {
  constructor() {
    super('Phone')
  }
  language: string = ''
  timeformat: string = ''
  handset_plus_headset: string = ''
  default_ringtone: string = ''
  ringtonesrc: string = ''
  ringtoneid: string = ''
  wifi_enabled: boolean = false
  wifi_ssid: string = ''
  exts: Ext[] = []
  mpks: Mpk[] = []
}
Parse.Object.registerSubclass('Phone', Phone)

export const usePhones = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { user, logout } = useUserStore(({ user, logout }) => ({
    user,
    logout,
  }))

  const [phones, setPhones] = useState<Phone[] | null>(null)
  useEffect(() => {
    if (!phones && !loading && !error && user) {
      const query = new Parse.Query(Phone)
      query.ascending('createdAt')
      query.equalTo('user', user)
      query
        ?.find()
        .then((phones) => {
          setPhones(phones)
          setLoading(false)
        })
        .catch((e) => {
          if (e.message === 'Session token is expired.') {
            logout()
            console.log(e)
          } else {
            setError(e)
            console.log(e)
          }
        })
    }
  }, [phones, loading, error, user, logout])
  return { phones, setPhones, setError }
}

export const usePhone = (error: Error | null, phoneId?: string) => {
  const [loading, setLoading] = useState(false)

  const [phone, setPhone] = useState<Phone | null>(null)
  useEffect(() => {
    if (phoneId && !phone && !loading && !error) {
      setLoading(true)
      const query = new Parse.Query(Phone)
      query.ascending('createdAt')
      query.get(phoneId).then((phone) => {
        setPhone(phone)
        setLoading(false)
      })
    }
    if (!phoneId && phone) {
      setPhone(null)
    }
  }, [phoneId, phone, loading, error])
  return { phone, setPhone }
}

export const useExtsOrMpks = (phone: Phone | null, error: Error | null) => {
  const { user, refetchUser } = useUserStore(({ user, refetchUser }) => ({
    user,
    refetchUser,
  }))
  const [loading, setLoading] = useState(false)
  const KeyClass =
    phone?.get('ext_number') > 0
      ? Ext
      : phone?.get('mpk_number') > 0
      ? Mpk
      : null
  const [extsOrMpks, setExtsOrMpks] = useState<ExtOrMpk[] | null>(null)
  const [newKey, setNewKey] = useState<ExtOrMpk | null>(null)
  const createNewKey = () => {
    if (KeyClass) {
      const newExtOrMpk = new KeyClass()
      newExtOrMpk.set('name', 'Nouveau')
      newExtOrMpk.set('account', '0')
      newExtOrMpk.set('type', '0')
      newExtOrMpk.set('phone', phone)
      newExtOrMpk.setACL(new Parse.ACL(user))
      setNewKey(newExtOrMpk)
    }
  }
  const fieldName = `${KeyClass?.name.toLowerCase()}s`
  const currentNumberOfExtsOrMpks =
    KeyClass === Ext ? phone?.get('ext_number') : phone?.get('mpk_number')
  const canAddMore = !!(
    KeyClass &&
    extsOrMpks &&
    extsOrMpks?.length < currentNumberOfExtsOrMpks
  )
  const saveNewKey = async (values: Record<string, any>) => {
    if (newKey && extsOrMpks) {
      setLoading(true)
      for (const [key, value] of Object.entries(values)) {
        newKey.set(key, value)
      }
      const savedNewKey = await newKey.save()
      setLoading(false)
      refetchUser()
      if (savedNewKey) {
        setNewKey(null)
        setExtsOrMpks([...extsOrMpks, savedNewKey])
      }
    }
  }
  const updateExtOrMpk = async (i: number, values: Record<string, any>) => {
    if (extsOrMpks) {
      setLoading(true)
      const extOrMpk = extsOrMpks[i]
      for (const [key, value] of Object.entries(values)) {
        extOrMpk?.set(key, value)
      }
      const updatedExtOrMpk = await extOrMpk.save()
      if (updatedExtOrMpk) {
        setExtsOrMpks([
          ...extsOrMpks.slice(0, i),
          updatedExtOrMpk,
          ...extsOrMpks.slice(i + 1),
        ])
      }
      setLoading(false)
      refetchUser()
    }
  }
  const deleteExtOrMpk = async (i: number) => {
    if (extsOrMpks) {
      setLoading(true)
      const extOrMpk = extsOrMpks[i]
      await extOrMpk
        .destroy()
        .then(() => {
          setExtsOrMpks([...extsOrMpks.slice(0, i), ...extsOrMpks.slice(i + 1)])
        })
        .finally(() => {
          setLoading(false)
          refetchUser()
        })
    }
  }
  const fetchExtsOrMpks = useCallback(() => {
    if (phone && KeyClass && !extsOrMpks && !loading && !error) {
      setLoading(true)
      const query = new Parse.Query<ExtOrMpk>(KeyClass)
      query.ascending('createdAt')
      query.equalTo('phone', phone)
      query
        .find()
        .then((res) => {
          setExtsOrMpks(res)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [phone, KeyClass, loading, error, extsOrMpks])

  useEffect(() => fetchExtsOrMpks(), [fetchExtsOrMpks])
  return {
    extsOrMpks,
    fieldName,
    fetchExtsOrMpks,
    canAddMore,
    newKey,
    loading,
    updateExtOrMpk,
    deleteExtOrMpk,
    deleteNewKey: () => setNewKey(null),
    createNewKey,
    saveNewKey,
  }
}
