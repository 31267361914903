import create from 'zustand'
import Parse from 'parse'
import { useCallback, useEffect } from 'react'
import useUserStore from './userState'

export class HelpArticle extends Parse.Object {
  title: string = ''
  content: string = ''
}

type ArticleStore = {
  articles: HelpArticle[]
  fetchArticles: (u: Parse.User) => Promise<void>
}

export const useArticleStore = create<ArticleStore>((set) => {
  return {
    articles: [],
    fetchArticles: (user: Parse.User) => {
      return user
        .relation<HelpArticle>('help_articles')
        .query()
        .find()
        .then((res) =>
          set({
            articles: res.sort((a, b) =>
              a.get('index') - b.get('index')
            ),
          })
        )
        .catch((err) => {
          console.log(err)
          set({ articles: [] })
        })
    },
  }
})

export const useHelpArticles = () => {
  const user = useUserStore(
    useCallback(({ user }) => {
      return user
    }, [])
  )
  const { fetchArticles, articles } = useArticleStore()
  useEffect(() => {
    if (user) {
      fetchArticles(user)
    }
  }, [fetchArticles, user])
  return { articles }
}
